import { memo, useCallback, useState } from 'react';

import LinkButton from 'components/ui/shared/buttons/linkButton';
import MultiFacetGroup from 'components/ui/lists/filters/facetGroups/multiFacetGroup';
import { FormattedFacetGroup, GroupedFacetGroupConfigItem } from './interfaces/facetGroupTypes';
import { RouterProps, withRouter } from 'constants/reactRouter';
import { getUrlParams } from 'utils/urlUtils';
import { t } from 'utils/intlUtils';

import style from './collapsibleMultiFacetGroup.scss';

export const CMFG_URL_PARAM_NAME = '_cMFG';

interface CollapsibleMultiFacetGroupProps extends RouterProps {
  /** The facet group config */
  config: GroupedFacetGroupConfigItem;
  /** Invoked on facet group button click. */
  onEdit: (name: string, label?: string) => void;
  /** The facet groups. */
  facetGroups: FormattedFacetGroup[];
}

const CollapsibleMultiFacetGroup = ({
  config,
  facetGroups,
  onEdit,
  router,
  location,
}: CollapsibleMultiFacetGroupProps) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(
    !location.query[CMFG_URL_PARAM_NAME]?.includes?.(config.name)
  );

  // Determine which facet groups should be visible
  const { defaultItemsVisible = 2 } = config;
  const visibleItems: GroupedFacetGroupConfigItem['items'] = config.items
    .map((item, index) =>
      !isCollapsed || index < defaultItemsVisible || !!location.query[item.name] ? item : undefined
    )
    .filter(Boolean);

  // Determine if group can be collapsed
  const isCollapsible = !!location.query[CMFG_URL_PARAM_NAME] || visibleItems.length < config.items.length;

  /**
   * Callback to toggle the `isCollapsed` state value, as well as update the url
   */
  const onToggleCollapse = useCallback(() => {
    // TODO: Create a urlUtils helper for this:
    // https://einc.atlassian.net/browse/EB-19107
    const urlParams = getUrlParams();
    let vals = urlParams[CMFG_URL_PARAM_NAME] || [];
    if (!(vals instanceof Array)) {
      vals = [vals];
    }
    if (vals.includes(config.name)) {
      vals = vals.filter((v) => v !== config.name);
    } else {
      vals = [...vals, config.name];
    }
    if (vals.length === 0) {
      delete urlParams[CMFG_URL_PARAM_NAME];
    } else {
      urlParams[CMFG_URL_PARAM_NAME] = vals;
    }

    // Update internal state and update url params
    setIsCollapsed(!isCollapsed);
    router.push({ ...location, query: urlParams });
  }, [config, isCollapsed, location, router]);

  return (
    <>
      {visibleItems.map((configItem) => (
        <MultiFacetGroup
          key={configItem.name}
          disabled={configItem.disabled}
          facetGroup={facetGroups.find((f) => f.name === configItem.name)}
          label={configItem.label}
          onEdit={onEdit}
        />
      ))}
      {isCollapsible && (
        <LinkButton className={style.toggleButton} onClick={onToggleCollapse} theme="blue">
          {t(isCollapsed ? 'more' : 'less')}
        </LinkButton>
      )}
    </>
  );
};

export default withRouter(memo(CollapsibleMultiFacetGroup));

import AddModifyFeeValue, {
  FieldTypes,
  getUserAction,
  TrackingAction,
  TrackingClickType,
} from 'components/sections/inventoryItem/details/feesAndValues/addModifyFeeValue';
import BaseClass from 'components/ui/shared/base';
import FeeValueItem, { FeeValueRow } from 'components/sections/inventoryItem/details/feesAndValues/feeValueItem';
import FeeValuesInfoRow from 'components/sections/inventoryItem/details/feesAndValues/feeValuesInfoRow';
import InventoryItem from 'constants/inventoryItem';
import User from 'constants/user';
import { DetailsSection } from 'layouts/list/listLayout';
import { InventoryItemValue, InventoryItemValueType } from 'store/shared/api/graph/interfaces/types';
import { UserAction } from 'logging/analytics/events/userActions';
import { blackBookValueTypes } from 'utils/formatting/blackBookFormatUtils';
import { getInventoryItemValuesMetaData } from 'store/shared/api/graph/queries/inventoryItem';
import { marketGuideValueTypes } from 'utils/formatting/marketGuideFormatUtils';
import { mmrValueTypes } from 'utils/formatting/mmrFormatUtils';
import { t } from 'utils/intlUtils';

interface Props {
  /** The values section id. */
  id?: string;
  /** The inventory item details. */
  inventoryItem: InventoryItem;
  /** True when item is an inventory item. */
  isInventoryItem?: boolean;
  /** Callback function to track user actions. */
  trackUserAction?: (action: UserAction) => void;
  /** The current user. */
  user: User;
}

interface State {
  /** A collection of inventory item values metadata. */
  inventoryItemValuesMetaData: InventoryItemValueType[] | undefined;
  /** True when querying for item values metedata. */
  isFetchingMetaData: boolean;
  /** True when dialog is open. */
  isFormDialogOpen: boolean;
  /** The selected value to edit. */
  selectedValue: InventoryItemValue | undefined;
}

class Values extends BaseClass<Props, State> {
  static defaultProps = {
    isInventoryItem: true,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      isFetchingMetaData: false,
      isFormDialogOpen: false,
      selectedValue: undefined,
      inventoryItemValuesMetaData: undefined,
    };

    this.getValuesMetaData();
  }

  componentDidUpdate(prevProps: Props) {
    const { inventoryItem } = prevProps;
    const { inventoryItem: inventoryItemNext } = this.props;

    if (inventoryItemNext?.id && inventoryItem?.id !== inventoryItemNext?.id) {
      this.getValuesMetaData();
    }
  }

  getValuesMetaData = () => {
    this.setState({ isFetchingMetaData: true });
    getInventoryItemValuesMetaData(this.props.inventoryItem?.id)
      .then((response) =>
        this.setState({ inventoryItemValuesMetaData: response?.data?.data?.inventoryItemValuesMetaData })
      )
      .catch(this.onApiError)
      .finally(() => this.setState({ isFetchingMetaData: false }));
  };

  onClickAddButton = () => {
    const { trackUserAction } = this.props;
    trackUserAction?.(UserAction.VDP_VALUES_ADD_CLICK);
    this.setState({ isFormDialogOpen: true });
  };

  render() {
    const { id, inventoryItem, isInventoryItem, trackUserAction } = this.props;
    const { inventoryItemValuesMetaData, isFormDialogOpen, selectedValue } = this.state;

    if (!!inventoryItemValuesMetaData?.length && !inventoryItem?.values) {
      return null;
    }

    const valueItems: InventoryItemValue[] =
      inventoryItem?.values
        ?.filter(
          (value) =>
            value &&
            !blackBookValueTypes.includes(value.type.id) &&
            !mmrValueTypes.includes(value.type.id) &&
            !marketGuideValueTypes.includes(value.type.id)
        )
        .filter(Boolean) || [];

    const valueItemsNotSet = inventoryItemValuesMetaData?.filter((value) => value?.canAdd);
    // Display no values when inventory item has no values and is not a Canadian auction item
    const hasNoInventoryItemValues =
      !inventoryItem?.values?.filter(Boolean)?.length &&
      (isInventoryItem || inventoryItem.location?.countryCode !== 'CA');
    const hasAllValues = !valueItemsNotSet?.length;

    return (
      <>
        <DetailsSection
          id={id}
          isEmpty={hasNoInventoryItemValues}
          onClickSubButton={!hasAllValues ? this.onClickAddButton : null}
          subButtonDataTestId="addModifyValuesButton"
          subButtonType="add"
          title={hasNoInventoryItemValues ? t('no_values') : t('values')}
        >
          <FeeValuesInfoRow
            countryCode={inventoryItem?.location?.countryCode}
            inventoryItemValues={inventoryItem?.values?.filter(Boolean)}
            make={inventoryItem?.make}
            model={inventoryItem?.model}
            subModel={inventoryItem?.subModel}
            year={inventoryItem?.year}
          />
          {valueItems.length ? (
            <FeeValueRow>
              {valueItems.map((value) => (
                <FeeValueItem
                  key={value.id}
                  isUpdatable={value?.type?.canUpdate}
                  item={{
                    amount: value?.value?.amount,
                    amountFormatted: value?.value?.formattedAmount,
                    created: value?.created,
                    createdBy: value?.createdBy || undefined,
                    expiryDate: value?.expiryDate || undefined,
                    id: value?.type?.id,
                    note: value?.notes || undefined,
                    title: value?.type?.name,
                    updated: value?.updated || undefined,
                    updatedBy: value?.updatedBy || undefined,
                  }}
                  onEdit={() => {
                    const userAction = getUserAction(
                      FieldTypes.VALUE,
                      TrackingAction.EDIT,
                      TrackingClickType.CLICK,
                      value?.type?.id
                    );
                    userAction && trackUserAction?.(userAction);
                    this.setState({ isFormDialogOpen: true, selectedValue: value });
                  }}
                />
              ))}
            </FeeValueRow>
          ) : null}
        </DetailsSection>

        <AddModifyFeeValue
          availableFields={valueItemsNotSet}
          fieldType={FieldTypes.VALUE}
          inventoryItem={inventoryItem}
          isInventoryItem={isInventoryItem}
          isOpen={isFormDialogOpen}
          onClose={() => {
            this.getValuesMetaData();
            this.setState({ isFormDialogOpen: false, selectedValue: undefined });
          }}
          selectedField={selectedValue}
          trackUserAction={trackUserAction}
        />
      </>
    );
  }
}

export default Values;

import classnames from 'classnames';

import Button from 'components/ui/shared/button';
import { FacetLabelGroup } from 'components/ui/lists/filters/facetGroups/interfaces/facetGroupTypes';
import { t } from 'utils/intlUtils';

import style from './multiFacetGroup.scss';

export interface MultiFacetGroupProps {
  /** Whether the facet group is disabled */
  disabled?: boolean;
  /** The facet group. */
  facetGroup: FacetLabelGroup | undefined;
  /** The label of the facet group. */
  label: string | undefined;
  /** Invoked on facet group button click. */
  onEdit: (name: string, label?: string) => void;
}

const MultiFacetGroup = ({ disabled, facetGroup, label, onEdit }: MultiFacetGroupProps) => {
  if (!facetGroup) {
    return null;
  }

  return (
    <Button
      className={classnames(style.multiFacetGroup, {
        [style.selected]: !!facetGroup.facets?.find((facet) => facet.selected),
      })}
      dataTestId={`${facetGroup.name}-filter-button`}
      disabled={disabled || facetGroup.facets?.length === 0}
      onClick={() => onEdit(facetGroup.name, label)}
      theme="none"
    >
      {facetGroup.facets
        ?.filter((facet) => facet.selected)
        .map((facet) => facet.facetLabel)
        .join(', ') || t('any_x', [label || facetGroup.name])}
    </Button>
  );
};

export default MultiFacetGroup;

// extracted by mini-css-extract-plugin
export default {"colorBlack":"#1d2126","colorBlackTransparent":"#1d212680","colorBlue":"#378ccb","colorBlueDark":"#0c65a7","colorBlueLighter":"#ebf4fa","colorGrayDark":"#5b656d","colorGrayDarker":"#313d48","colorGrayDarkest":"#24292f","colorGrayLight":"#adb2b6","colorGrayLighter":"#d6d8da","colorGrayLightest":"#ebeced","colorGreen":"#26b899","colorGreenLightest":"#e9f8f5","colorRed":"#e76f67","colorRedLightest":"#fdf1f0","colorText":"#24292f","colorWhite":"#fff","colorYellow":"#f6b551","fontWeightThin":"200","fontWeightRegular":"400","fontWeightSemiBold":"600","fontWeightBold":"700","fontSize10":".625rem","zIndexNeg1":"-1","zIndex0":"0","zIndex1":"1","zIndex2":"2","zIndex3":"3","zIndex4":"4","zIndex5":"5","type-body-1":"type-body-1_cf5e4ec","type-body-2":"type-body-2_b858bda","type-body-3":"type-body-3_ddfed23","type-body-4":"type-body-4_d448259","type-section-title":"type-section-title_b453aec","type-notes":"type-notes_ccce7a5","type-item-title":"type-item-title_cce446a","type-item-subtitle":"type-item-subtitle_f2d1456","type-subtitle":"type-subtitle_f8bb578","type-title":"type-title_a3b084b","input":"input_e7af79d","title":"title_ffe072d","dialog":"dialog_fce97ab","inputContainer":"inputContainer_fb4ed52","button":"button_bf7618a"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"colorBlack\":\"#1d2126\",\"colorBlackTransparent\":\"#1d212680\",\"colorBlue\":\"#378ccb\",\"colorBlueDark\":\"#0c65a7\",\"colorBlueLighter\":\"#ebf4fa\",\"colorGrayDark\":\"#5b656d\",\"colorGrayDarker\":\"#313d48\",\"colorGrayDarkest\":\"#24292f\",\"colorGrayLight\":\"#adb2b6\",\"colorGrayLighter\":\"#d6d8da\",\"colorGrayLightest\":\"#ebeced\",\"colorGreen\":\"#26b899\",\"colorGreenLightest\":\"#e9f8f5\",\"colorRed\":\"#e76f67\",\"colorRedLightest\":\"#fdf1f0\",\"colorText\":\"#24292f\",\"colorWhite\":\"#fff\",\"colorYellow\":\"#f6b551\",\"fontWeightThin\":\"200\",\"fontWeightRegular\":\"400\",\"fontWeightSemiBold\":\"600\",\"fontWeightBold\":\"700\",\"fontSize10\":\".625rem\",\"zIndexNeg1\":\"-1\",\"zIndex0\":\"0\",\"zIndex1\":\"1\",\"zIndex2\":\"2\",\"zIndex3\":\"3\",\"zIndex4\":\"4\",\"zIndex5\":\"5\",\"type-body-1\":\"type-body-1_cf5e4ec\",\"type-body-2\":\"type-body-2_b858bda\",\"type-body-3\":\"type-body-3_ddfed23\",\"type-body-4\":\"type-body-4_d448259\",\"type-section-title\":\"type-section-title_b453aec\",\"type-notes\":\"type-notes_ccce7a5\",\"type-item-title\":\"type-item-title_cce446a\",\"type-item-subtitle\":\"type-item-subtitle_f2d1456\",\"type-subtitle\":\"type-subtitle_f8bb578\",\"type-title\":\"type-title_a3b084b\",\"input\":\"input_e7af79d\",\"title\":\"title_ffe072d\",\"dialog\":\"dialog_fce97ab\",\"inputContainer\":\"inputContainer_fb4ed52\",\"button\":\"button_bf7618a\"}";
        // 1739550724559
        var cssReload = require("../../../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  
import AuctionItem from 'constants/auctionItem';
import { AuctionItemFormat } from 'store/shared/api/graph/interfaces/types';
import { joinStrings } from 'utils/stringUtils';
import { t } from 'utils/intlUtils';

/**
 * Returns a formatted auction title
 */
export const getAuctionNameByDetails = (auctionItem: Pick<AuctionItem, 'auction' | 'displayRunNumber' | 'format'>) => {
  const { auction, displayRunNumber, format } = auctionItem;
  switch (format) {
    case AuctionItemFormat.APPRAISAL:
      return joinStrings([t('auction_item_format_appraisal'), auction?.title], ' - ');

    case AuctionItemFormat.AUCTION:
    case AuctionItemFormat.AUCTION_PHYSICAL:
      return joinStrings([displayRunNumber, auction?.title], ' - ');

    case AuctionItemFormat.GROUNDED:
      return joinStrings([t('timed_auction'), auction?.title], ' - ');

    case AuctionItemFormat.TIMED_OFFER:
      return joinStrings([t('buy_now'), auction?.title], ' - ');

    default:
      return null;
  }
};

/**
 * Returns the section name based on the AuctionItem's format.
 * Primarily used in the Browse section's list items.
 */
export const getAuctionItemSectionNameByFormat = (auctionItem: Pick<AuctionItem, 'format'>) => {
  switch (auctionItem.format) {
    case AuctionItemFormat.APPRAISAL:
      return t('auction_item_format_appraisal');

    case AuctionItemFormat.AUCTION:
    case AuctionItemFormat.AUCTION_PHYSICAL:
      return t('live_auction');

    case AuctionItemFormat.GROUNDED:
      return t('timed_auction');

    case AuctionItemFormat.TIMED_OFFER:
      return t('buy_now');

    default:
      return null;
  }
};

export const getIfBidCompany = (auctionItem) => {
  const ifBidTimelineList = auctionItem?.ifBidTimeline?.list;
  const seller = auctionItem?.inventoryItem?.company;
  const buyer = ifBidTimelineList[ifBidTimelineList?.length - 1]?.company;
  const latestConsigner = ifBidTimelineList?.[0]?.company;
  return latestConsigner.id === seller.id ? buyer : seller;
};

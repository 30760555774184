import {
  FacetGroupConfig,
  GroupedFacetGroupConfigItem,
} from 'components/ui/lists/filters/facetGroups/interfaces/facetGroupTypes';
import { formatNumber } from 'utils/stringUtils';
import { t } from 'utils/intlUtils';

export const mileageRange = [0, 300000];
export const vehicleScoreRange = [0, 5];
export const yearRange = [1980, new Date().getFullYear() + 1];

/**
 * Generates labels for mileage range sliders.
 */
export const mileageValueLabel = (mileage: number[]): string => {
  if (mileage[0] === mileageRange[0] && mileage[1] === mileageRange[1]) {
    return t('any');
  }
  if (mileage[0] === mileageRange[0]) {
    return t('x_and_below', [formatNumber(mileage[1])]);
  }
  if (mileage[1] === mileageRange[1]) {
    return t('x_and_above', [formatNumber(mileage[0])]);
  }
  return `${formatNumber(mileage[0])} - ${formatNumber(mileage[1])}`;
};

/**
 * Generates labels for year range sliders.
 */
export const yearValueLabel = (years: number[]): string => {
  if (years[0] === yearRange[0] && years[1] === yearRange[1]) {
    return t('any');
  }
  if (years[0] === yearRange[0]) {
    return t('x_and_older', [formatNumber(years[1])]);
  }
  if (years[1] === yearRange[1]) {
    return t('x_and_newer', [formatNumber(years[0])]);
  }
  return `${years[0]} - ${years[1]}`;
};

/**
 * Generates labels for vehicle score range sliders.
 */
export const vehicleScoreValueLabel = (scores: number[]): string => {
  return `${scores[0]} - ${scores[1]}`;
};

/**
 * Combine all available facet group config entries into flattened array
 */
export const flattenFacetGroups = (config: FacetGroupConfig) =>
  config.reduce((allEntries, entry) => {
    return [...allEntries, 'items' in entry ? entry.items : entry].flat();
  }, []);

/**
 * Applies any required filter to a facet group config
 */
export const filterFacetGroupConfig = (
  config: FacetGroupConfig,
  filterCb: (config: FacetGroupConfig) => FacetGroupConfig
) => {
  return filterCb(
    config.map((item) => {
      if ('items' in item) {
        return { ...item, items: filterCb(item.items) } as GroupedFacetGroupConfigItem;
      }
      return item;
    })
  );
};

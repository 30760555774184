import { createAction } from 'redux-actions';

import { AppDispatch } from 'store/configureStore';
import {
  AuctionItemUpdateInput,
  MutationauctionItemAcceptOfferArgs,
  MutationauctionItemDeclineOfferArgs,
  MutationauctionItemMakeOfferArgs,
} from 'store/shared/api/graph/interfaces/types';
import { FacetName } from 'constants/enums/facets';
import { STATUS } from 'constants/enums/auctionItem';
import {
  acceptOffer,
  archiveAuctionItem,
  arriveAuctionItem,
  auctionItemChangeBuyer,
  auctionItemSetSellerIntent,
  cancelIfBid,
  cancelPendingDelivery,
  cancelSale,
  declineOffer,
  forceReserveMet,
  getAuctionItemDetails,
  ifBidAccept,
  ifBidCounter,
  inventoryItemShare,
  makeOffer,
  pullAuctionItem,
  requestArbitration,
  setAsIs,
  setAuctionItemAutobid,
  setAuctionItemWatching,
  setHoldback,
  submitBid,
  transferRunningItem,
} from './auctionItemDetailsApi';
import { auctionItemUpdate } from 'store/shared/api/graph/mutations/auctionItemUpdate';
import {
  auctionItemsListRemoveItem,
  auctionItemsListUpdateItem,
  updateFacetCount,
} from '../auctionItemsList/auctionItemsActions';
import { getOptionsWithRequestSequence } from 'utils/apiUtils';

export const auctionItemAddNote = createAction('AUCTION_ITEM_DETAILS_ADD_NOTE');
export const auctionItemDetailsLoaded = createAction('AUCTION_ITEM_DETAILS_LOADED');
export const auctionItemDetailsUpdating = createAction('AUCTION_ITEM_DETAILS_UPDATING');
export const auctionItemSellerDetailsLoaded = createAction('AUCTION_ITEM_DETAILS_SELLER_DETAILS_LOADED');
export const auctionItemUpdateInventoryItem = createAction('AUCTION_ITEM_DETAILS_UPDATE_INVENTORY_ITEM');
export const clearAuctionItemDetails = createAction('AUCTION_ITEM_DETAILS_CLEAR');
export const holdbackSet = createAction('AUCTION_ITEM_DETAILS_HOLDBACK_SET');
export const permissionDenied = createAction('AUCTION_ITEM_DETAILS_PERMISSION_DENIED');
export const preloadAuctionItem = createAction('AUCTION_ITEM_DETAILS_PRELOAD');
export const updateArchived = createAction('AUCTION_ITEM_DETAILS_UPDATE_ARCHIVED');
export const updateAuctionItemOrder = createAction('AUCTION_ITEM_DETAILS_UPDATE_AUCTION_ITEM_ORDER');

export const processGetAuctionItemDetails = (options, dispatch) => {
  dispatch(auctionItemDetailsUpdating());
  return getAuctionItemDetails(options)
    .then((response) => {
      const auctionItem = response?.data?.data?.auctionItem;
      if (auctionItem === null) {
        dispatch(permissionDenied());
      } else if (auctionItem) {
        const formattedResponse = getOptionsWithRequestSequence(response, response?.data?.data?.auctionItem);
        dispatch(auctionItemDetailsLoaded(formattedResponse));
      }
    })
    .catch((err) => {
      console.warn('Failed to fetch auctionItemDetails:', err);
    });
};

export const processRefreshAuctionItemDetails = (options, dispatch, onlyIfDifferent = true) => {
  return getAuctionItemDetails(options)
    .then((response) => {
      if (response?.data?.data?.auctionItem === null) {
        dispatch(permissionDenied());
      } else {
        const formattedResponse = getOptionsWithRequestSequence(response, {
          ...response?.data?.data?.auctionItem,
          onlyIfDifferent,
          context: options.context,
        });

        dispatch(auctionItemDetailsLoaded(formattedResponse));
      }
    })
    .catch((err) => {
      console.warn('Failed to fetch auctionItemDetails:', err);
    });
};

export const processSetAuctionItemWatching = (options, dispatch) => {
  return setAuctionItemWatching(options).then((response) => {
    const auctionItem = response?.data?.data?.auctionItemSetWatching;
    dispatch(auctionItemsListUpdateItem(auctionItem));

    const payload = { facetName: FacetName.WATCHING, increment: options.watching };
    dispatch(updateFacetCount(payload));

    const formattedResponse = getOptionsWithRequestSequence(response, {
      ...auctionItem,
      onlyIfDifferent: true,
    });
    return dispatch(auctionItemDetailsLoaded(formattedResponse));
  });
};

export const processSetAuctionItemAutobid = (options, dispatch) => {
  return setAuctionItemAutobid(options).then((response) => {
    const auctionItem = response?.data?.data?.auctionItemSetAutoBid;
    const formattedResponse = getOptionsWithRequestSequence(response, {
      ...auctionItem,
      onlyIfDifferent: true,
    });

    dispatch(auctionItemsListUpdateItem(auctionItem));
    const payload = { facetName: FacetName.AUTOBIDS, increment: auctionItem?.autoBids?.myAutoBid };
    dispatch(updateFacetCount(payload));
    return dispatch(auctionItemDetailsLoaded(formattedResponse));
  });
};

export const processPullFromAuction = (options, dispatch) => {
  return pullAuctionItem(options).then((response) => {
    const auctionItem = response?.data?.data?.auctionItemPullFromAuction;
    const formattedResponse = getOptionsWithRequestSequence(response, auctionItem);

    dispatch(auctionItemDetailsLoaded(formattedResponse));
    return dispatch(auctionItemsListRemoveItem(auctionItem));
  });
};

export const processGetShareUrl = (options) => {
  return inventoryItemShare(options).then((response) => response?.data?.data?.inventoryItemShare);
};

export const processAuctionItemArrived = (options, dispatch) => {
  return arriveAuctionItem(options).then((response) => {
    const auctionItem = response?.data?.data?.auctionItemArrived;
    const formattedResponse = getOptionsWithRequestSequence(response, auctionItem);
    dispatch(auctionItemDetailsLoaded(formattedResponse));
    return dispatch(auctionItemsListRemoveItem(auctionItem));
  });
};

export const processRequestArbitration = (options, dispatch) => {
  return requestArbitration(options).then((response) =>
    dispatch(
      auctionItemDetailsLoaded(
        getOptionsWithRequestSequence(response, response?.data?.data?.auctionItemRequestArbitration)
      )
    )
  );
};

export const processAuctionItemIfBidAccept = (options, dispatch) => {
  return ifBidAccept(options).then((response) => {
    const formattedResponse = response?.data?.data?.auctionItemIfBidAccept;
    dispatch(auctionItemDetailsLoaded(formattedResponse));
    return formattedResponse;
  });
};

export const processAuctionItemIfBidCounter = (options, dispatch) => {
  return ifBidCounter(options).then((response) => {
    const formattedResponse = response?.data?.data?.auctionItemIfBidCounter;
    dispatch(auctionItemDetailsLoaded(getOptionsWithRequestSequence(response, formattedResponse)));
    return formattedResponse;
  });
};

export const processSubmitBid = (options) => {
  return submitBid(options).then((response) => response?.data?.data?.auctionItemSubmitBid);
};

export const processSetHoldback = async (options, dispatch) => {
  const response = await setHoldback(options);

  dispatch(holdbackSet(response?.data?.data?.auctionItemSetHoldback));

  processRefreshAuctionItemDetails(
    { auctionItemId: options.auctionItemId, holdbackActive: options.holdbackActive },
    dispatch
  );
};

export const processForceReserveMet = async (options, dispatch) => {
  const response = await forceReserveMet(options.auctionItemId);
  processRefreshAuctionItemDetails(options, dispatch);
  return response;
};

export const processArchiveAuctionItem = (options, dispatch) => {
  return archiveAuctionItem(options).then((response) =>
    dispatch(updateArchived(response?.data?.data?.auctionItemArchive))
  );
};

export const processCancelAuctionItem = async (options, dispatch) => {
  const cancelFunc = Object.freeze({
    [STATUS.IN_IF_BID]: cancelIfBid,
    [STATUS.PENDING_DELIVERY]: cancelPendingDelivery,
    [STATUS.AWAITING_CHECKOUT]: cancelSale,
    [STATUS.SOLD]: cancelSale,
  });

  const response = await cancelFunc[options.status](options);
  processRefreshAuctionItemDetails(options, dispatch);
  return response;
};

export const processSetAsIs = async (options, dispatch) => {
  const response = await setAsIs(options);
  processRefreshAuctionItemDetails(options, dispatch);
  return response;
};

export const processTransferRunningItem = async (options, dispatch) => {
  const response = await transferRunningItem(options);
  processRefreshAuctionItemDetails(options, dispatch);
  return response?.data?.data?.auctionItemTransferRunning;
};

export const processChangeBuyer = async (options, dispatch) => {
  return auctionItemChangeBuyer(options).then((response) =>
    dispatch(updateArchived(response?.data?.data?.auctionItemChangeBuyer))
  );
};

export const processSetSellerIntent = async (options, dispatch) => {
  const response = await auctionItemSetSellerIntent(options);
  processRefreshAuctionItemDetails(options, dispatch);
  return response;
};

export const processAuctionItemUpdate = async (options: AuctionItemUpdateInput, dispatch: AppDispatch) => {
  const response = await auctionItemUpdate(options);
  processRefreshAuctionItemDetails({ auctionItemId: response?.data?.data?.auctionItemUpdate.id }, dispatch);

  return response?.data?.data?.auctionItemUpdate;
};

const processBaseMakeOffer = async (options: MutationauctionItemMakeOfferArgs, dispatch: AppDispatch) => {
  const response = await makeOffer(options);
  const auctionItem = response?.data.data?.auctionItemMakeOffer;
  const formattedResponse = getOptionsWithRequestSequence(response, {
    ...auctionItem,
    onlyIfDifferent: true,
  });

  dispatch(auctionItemsListUpdateItem(auctionItem));
  dispatch(auctionItemDetailsLoaded(formattedResponse));

  return auctionItem;
};

export interface MutationAuctionItemMakeOfferArgs {
  input: Omit<MutationauctionItemMakeOfferArgs['input'], 'appliedToBuyNow' | 'comment' | 'offerId'>;
}

export const processMakeOffer = async (options: MutationAuctionItemMakeOfferArgs, dispatch: AppDispatch) => {
  await processBaseMakeOffer(options, dispatch);
  dispatch(updateFacetCount({ facetName: FacetName.OFFER, increment: true }));
  dispatch(updateFacetCount({ facetName: FacetName.WAITING_ON_THEM, increment: true }));
};

export const processCounterOffer = async (options: MutationauctionItemMakeOfferArgs, dispatch: AppDispatch) => {
  await processBaseMakeOffer(options, dispatch);
  dispatch(updateFacetCount({ facetName: FacetName.WAITING_ON_YOU, increment: false }));
  dispatch(updateFacetCount({ facetName: FacetName.WAITING_ON_THEM, increment: true }));
};

export const processAcceptOffer = async (options: MutationauctionItemAcceptOfferArgs, dispatch: AppDispatch) => {
  const response = await acceptOffer(options);
  const auctionItem = response?.data.data?.auctionItemAcceptOffer;
  const formattedResponse = getOptionsWithRequestSequence(response, {
    ...auctionItem,
    onlyIfDifferent: true,
  });

  dispatch(auctionItemsListUpdateItem(auctionItem));
  dispatch(auctionItemDetailsLoaded(formattedResponse));
  dispatch(updateFacetCount({ facetName: FacetName.OFFER, increment: false }));
  dispatch(updateFacetCount({ facetName: FacetName.WAITING_ON_YOU, increment: false }));

  return auctionItem;
};

export const processDeclineOffer = async (options: MutationauctionItemDeclineOfferArgs, dispatch: AppDispatch) => {
  const response = await declineOffer(options);
  const auctionItem = response?.data.data?.auctionItemDeclineOffer;
  const formattedResponse = getOptionsWithRequestSequence(response, {
    ...auctionItem,
    onlyIfDifferent: true,
  });

  dispatch(auctionItemsListUpdateItem(auctionItem));
  dispatch(auctionItemDetailsLoaded(formattedResponse));
  dispatch(updateFacetCount({ facetName: FacetName.OFFER, increment: false }));
  dispatch(updateFacetCount({ facetName: FacetName.WAITING_ON_YOU, increment: false }));

  return auctionItem;
};

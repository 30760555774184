import { useCallback } from 'react';

import OperationButton from 'components/sections/inventoryItem/operations/operationButton';
import { RouterProps, withRouter } from 'constants/reactRouter';
import { t } from 'utils/intlUtils';
import { Route } from 'store/routing/routes';

interface Props extends RouterProps {
  auctionItemId: string;
  auctionTimeSlotId: string;
}

const JoinEvent = ({ auctionItemId, auctionTimeSlotId, router }: Props) => {
  /**
   * Re-routes to the Live Auction view and selects the unit's auctionTimeSlotLane as well as its VDP.
   */
  const onClick = useCallback(() => {
    const url = `${Route.BUY_LIVE_AUCTION}?id=${auctionItemId}&auctionTimeSlotId=${auctionTimeSlotId}`;
    router.push(url);
  }, [auctionItemId, auctionTimeSlotId, router]);

  return (
    <OperationButton onClick={onClick} theme="green">
      {t('join_event')}
    </OperationButton>
  );
};

export default withRouter(JoinEvent);

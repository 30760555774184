// extracted by mini-css-extract-plugin
export default {"colorBlack":"#1d2126","colorBlackTransparent":"#1d212680","colorBlue":"#378ccb","colorBlueDark":"#0c65a7","colorBlueLighter":"#ebf4fa","colorGrayDark":"#5b656d","colorGrayDarker":"#313d48","colorGrayDarkest":"#24292f","colorGrayLight":"#adb2b6","colorGrayLighter":"#d6d8da","colorGrayLightest":"#ebeced","colorGreen":"#26b899","colorGreenLightest":"#e9f8f5","colorRed":"#e76f67","colorRedLightest":"#fdf1f0","colorText":"#24292f","colorWhite":"#fff","colorYellow":"#f6b551","fontWeightThin":"200","fontWeightRegular":"400","fontWeightSemiBold":"600","fontWeightBold":"700","fontSize10":".625rem","zIndexNeg1":"-1","zIndex0":"0","zIndex1":"1","zIndex2":"2","zIndex3":"3","zIndex4":"4","zIndex5":"5","banner":"banner_e2954fc","content":"content_acc2db6","secondaryIconLink":"secondaryIconLink_bd2b8f5","secondaryIcon":"secondaryIcon_eff568b","iconWarning":"iconWarning_b854142","iconInfo":"iconInfo_bee8c43","theme-black":"theme-black_a965c33","theme-green":"theme-green_a2d2c5d","theme-green-solid":"theme-green-solid_f64d2ad","theme-gold":"theme-gold_de4ee02","theme-blue":"theme-blue_f47c7ca","theme-red":"theme-red_d3b499c"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"colorBlack\":\"#1d2126\",\"colorBlackTransparent\":\"#1d212680\",\"colorBlue\":\"#378ccb\",\"colorBlueDark\":\"#0c65a7\",\"colorBlueLighter\":\"#ebf4fa\",\"colorGrayDark\":\"#5b656d\",\"colorGrayDarker\":\"#313d48\",\"colorGrayDarkest\":\"#24292f\",\"colorGrayLight\":\"#adb2b6\",\"colorGrayLighter\":\"#d6d8da\",\"colorGrayLightest\":\"#ebeced\",\"colorGreen\":\"#26b899\",\"colorGreenLightest\":\"#e9f8f5\",\"colorRed\":\"#e76f67\",\"colorRedLightest\":\"#fdf1f0\",\"colorText\":\"#24292f\",\"colorWhite\":\"#fff\",\"colorYellow\":\"#f6b551\",\"fontWeightThin\":\"200\",\"fontWeightRegular\":\"400\",\"fontWeightSemiBold\":\"600\",\"fontWeightBold\":\"700\",\"fontSize10\":\".625rem\",\"zIndexNeg1\":\"-1\",\"zIndex0\":\"0\",\"zIndex1\":\"1\",\"zIndex2\":\"2\",\"zIndex3\":\"3\",\"zIndex4\":\"4\",\"zIndex5\":\"5\",\"banner\":\"banner_e2954fc\",\"content\":\"content_acc2db6\",\"secondaryIconLink\":\"secondaryIconLink_bd2b8f5\",\"secondaryIcon\":\"secondaryIcon_eff568b\",\"iconWarning\":\"iconWarning_b854142\",\"iconInfo\":\"iconInfo_bee8c43\",\"theme-black\":\"theme-black_a965c33\",\"theme-green\":\"theme-green_a2d2c5d\",\"theme-green-solid\":\"theme-green-solid_f64d2ad\",\"theme-gold\":\"theme-gold_de4ee02\",\"theme-blue\":\"theme-blue_f47c7ca\",\"theme-red\":\"theme-red_d3b499c\"}";
        // 1739550714989
        var cssReload = require("../../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  